// Packages
import { navigate } from 'gatsby'
import React from 'react'

// Assetss
import { isLoggedIn, isBrowser, getLocalStorageItem } from '../utils/storage'

const loginPath = '/auth/login'
const HomePage: React.FC = () => {
  const homePage = getLocalStorageItem('homePath') || loginPath
  return (
    <>
      {isLoggedIn() && isBrowser() && navigate(homePage)}

      {!isLoggedIn() && isBrowser() && navigate(loginPath)}
    </>
  )
}

export default HomePage
